import React, {
	useRef,
	useEffect,
} from "react";
import {
	Button,
	Box,
	Typography,
	Grid,
	Dialog,
	DialogContent,
} from "@mui/material";
import {
	Form,
	Formik
} from "formik";
import * as Yup from "yup";
import { RadioGroupFormik } from "../RadioGroup/RadioGroup";
import { AlertBox } from "../Alert/Alert";
import { DatePickerField } from "../../GoodsInCreate/components/DatePickerField";
import {
	getDisplayDate,
	getCurrentDateTruncated,
	getNextAvailableDeliveryDay,
} from "../../../utils/date";

export const DeliverySlot = ({
	open,
	onClose,
	estimatedDeliveryDate,
	getDeliverySlot,
	requestType,
	deliveryType,
	title,
	userdata,
	warehouseName,
	alwaysNeedSlot,
}) => {
	if (userdata.length === 0) return;
	const isUserAdmin = userdata.roles !== undefined && userdata.roles.find(r => r === "admin");

	const INITIAL_FORM_STATE = {
		delivery_slot: "",
		estimated_delivery_date: getNextAvailableDeliveryDay(estimatedDeliveryDate, isUserAdmin),
		is_customs_cleared: false,
		delivery_date_rejected: estimatedDeliveryDate,
		delivery_date_suggested: getNextAvailableDeliveryDay(estimatedDeliveryDate, isUserAdmin),
		delivery_time_suggested: "",
		delivery_date: new Date(estimatedDeliveryDate),
	};

	const FORM_VALIDATION = Yup.object().shape(
		requestType === "create" && {
			delivery_slot: Yup.string()
				.required("Please confirm if you would like to book a delivery slot now"),
		}
	);

	const divRef = useRef(null);
	const isContainerType = deliveryType && deliveryType.toLowerCase().includes("container");

	const FormInput = ({ props }) => {
		useEffect(() => {
			if (
				requestType === "request" || requestType === "approve" || requestType === "modify" || requestType === "reject"
			) {
				divRef.current.style.display = "block";
			}
		}, []);

		const handleBookDeliverySlotChange = (event) => {
			const value = event.target.value;
			if (value === "Yes") {
				divRef.current.style.display = "block";
			} else {
				divRef.current.style.display = "none";
			}
		};

		const handleIsCustomsClearedChange = (event) => {
			const value = event.target.value;
			event.target.value = value === "Yes";

			return props.handleChange(event);
		};

		const now = getCurrentDateTruncated();
		const nextAvailableDate = getNextAvailableDeliveryDay(now, isUserAdmin);

		return (
			<>
				{(!alwaysNeedSlot && (requestType === "create" || requestType === "request")) &&
					<Box>
						<AlertBox
							severity="warning"
							message={`Due to the size of the shipment, this ASN at ${warehouseName} requires a pre-booked delivery slot. Without an approved slot, your shipment will be refused, and penalty charges may apply. Note: Delivery slots must be booked at least 48 hours in advance.`}
							sx={{ mt: 3, mb: 3 }}
						/>
					</Box>}

				{(alwaysNeedSlot && (requestType === "create" || requestType === "request")) &&
					<Box>
						<AlertBox
							severity="warning"
							message={`All ASNs at ${warehouseName} require a pre-booked delivery slot. Without an approved slot, your shipment will be refused, and penalty charges may apply. Note: Delivery slots must be booked at least 48 hours in advance.`}
							sx={{ mt: 3, mb: 3 }}
						/>
					</Box>}

				{requestType === "create" &&
					<Box sx={{ mt: 3, mb: 2 }}>
						<RadioGroupFormik
							label="Would you like to book a delivery slot now?"
							name="delivery_slot"
							options={["Yes", "No, I'll book one later"]}
							labelPlacement="end"
							props={props}
							onChange={handleBookDeliverySlotChange}
						/>
					</Box>}

				<Box ref={divRef} style={{ display: "none" }}>
					{(requestType === "create" || requestType === "request" || requestType === "modify") &&
						<>
							{requestType === "modify" && (
								<Box>
									<Typography variant="p">The current delivery slot is {getDisplayDate(estimatedDeliveryDate)}.</Typography>
								</Box>
							)}
							<Box>
								<Typography variant="p">
									{requestType == "modify" ? "When would you like to reschedule your delivery slot?" : "When would you like a delivery slot?"}
								</Typography>
							</Box>
							<Box>
								<DatePickerField
									name="estimated_delivery_date"
									label="Estimated Delivery Date"
									defaultValue={props.values.estimated_delivery_date}
									disablePast={true}
									disableHighlightToday={true}
									shouldDisableDate={(date) => date < nextAvailableDate}
									sx={{ maxWidth: 175 }}
								/>
							</Box>

							{isContainerType &&
								<Box sx={{ mt: 3, mb: 2 }}>
									<RadioGroupFormik
										name="is_customs_cleared"
										label="Has your container cleared customs yet?"
										options={["Yes", "No"]}
										labelPlacement="end"
										props={props}
										onChange={handleIsCustomsClearedChange}
									/>
								</Box>}
						</>}

					{(requestType === "reject" || requestType === "approve") &&
						<>
							<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
								{requestType === "reject" &&
									<Grid item xs={6}>
										<Box>
											<Typography variant="p">
												Delivery Date Rejected
											</Typography>
										</Box>
										<Box>
											<DatePickerField
												name="delivery_date_rejected"
												label="Delivery Date Rejected"
												defaultValue={estimatedDeliveryDate}
												disablePast={false}
												disableHighlightToday={true}
												sx={{ maxWidth: 175 }}
											/>
										</Box>
									</Grid>}

								<Grid item xs={6}>
									<Box>
										<Typography variant="p">
											{requestType === "approve" ? "Delivery Date Approved" : "Delivery Date Suggested"}
										</Typography>
									</Box>
									<Box>
										<DatePickerField
											name="delivery_date"
											label={requestType === "approve" ? "Delivery Date Approved" : "Delivery Date Suggested"}
											defaultValue={props.values.delivery_date}
											disablePast={true}
											disableHighlightToday={true}
											shouldDisableDate={(date) => date < nextAvailableDate}
											sx={{ maxWidth: 175 }}
										/>
									</Box>
								</Grid>
							</Grid>

							{isContainerType &&
								<Box sx={{ mt: 3, mb: 2 }}>
									<RadioGroupFormik
										label={requestType === "approve" ? "Delivery Time Approved" : "Delivery Time Suggested"}
										name="delivery_time"
										options={["9am", "12pm"]}
										labelPlacement="end"
										props={props}
										onChange={props.handleChange}
									/>
								</Box>}
						</>}
				</Box>
			</>
		);
	};

	const ModalBody = () => {
		return (
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE
				}}
				enableReinitialize // disables validation on page load
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				onSubmit={(values, errors) => onSubmitModal(values, errors)}
			>
				{({ errors, values, touched, handleBlur, handleChange }) => (
					<Form
						noValidate
						autoComplete="off"
					>
						<Box>
							<Typography variant="h5" sx={{ mb: 3 }}>
								{title}
							</Typography>
							<Box mb={3}>
								<FormInput props={{ errors, values, touched, handleBlur, handleChange }} />
							</Box>
							<Grid
								container
								justifyContent="center"
								sx={{ mt: 2 }}
							>
								<Grid item>
									<Button
										variant="contained"
										type="submit"
									>
										Confirm
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Form>
				)}
			</Formik>
		);
	};

	const onSubmitModal = (values) => {
		if (requestType === "create" && values.delivery_slot === "") return;
		getDeliverySlot(values, requestType);
	};

	const Modal = () => {
		return (
			<Dialog
				open={open}
				onClose={onClose}
				keepMounted
				aria-describedby="alert-dialog-slide-description"
				fullWidth={true}
				maxWidth="sm"
				className="modal"
			>
				<DialogContent>
					<ModalBody />
				</DialogContent>
			</Dialog>
		);
	};

	return (
		<Modal />
	);

};