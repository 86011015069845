import React, {
	useEffect,
	useState,
} from "react";
import SellerDependentView from "../Shared/SellerDependentView";
import PermissionDependentView from "../Shared/PermissionDependentView/PermissionDependentView";
import {
	Box,
	Grid,
	Paper,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from "@mui/material";
import { DataContext } from "../../App";
import AuditLogsTable from "./components/Table";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import { 
	getCurrentDateTruncated,
	getFormattedTime,
} from "../../utils/date.js";
import {
	EndDatePicker,
	StartDatePicker
} from "../Shared/DatePicker";
import SearchFilter from "../Shared/SearchFilter/SearchFilter";
import { capitalizeFirstLetterOfEachWord } from "../../utils/text";

export default function AuditLogs({ seller, userdata }) {

	const context = React.useContext(DataContext);
	const initStartDate = getCurrentDateTruncated();
	const initEndDate = getCurrentDateTruncated();
	initStartDate.setDate(initStartDate.getDate() - 90);

	const [state, setState] = useState({
		isLoading: false,
		filter: "",
		startDate: initStartDate,
		endDate: initEndDate,
		itemData: [],
		operationData: [],		
		item: "",
		operation: "",	
		resultsData: [],
		resultsTotalCount: 0,
	});

	useEffect(() => {
		if (!seller) return;

		context.dataProvider.getAuditItems()
			.then(res => {
				if (!res) return;
				setState(previousState => {
					return {
						...previousState,
						itemData: res.data,
					};
				});
			});

		context.dataProvider.getAuditOperations()
			.then(res => {
				if (!res) return;
				setState(previousState => {
					return {
						...previousState,
						operationData: res.data,
					};
				});
			});

	}, []);

	useEffect(() => {

		if (!seller) return;

		const getSummaryData = () => {

			setState(previousState => {
				return {
					...previousState,
					isLoading: true,
				};
			});

			const params = new URLSearchParams({
				seller: seller,
				start_date: getFormattedTime(state.startDate),
				end_date: getFormattedTime(state.endDate),
				operation: state.operation,
				primitive: state.item,
				username: state.filter,
			});

			context.dataProvider.getAuditLogs(seller, params)
				.then(res => {
					if (!res) return;
					setState(previousState => {
						return {
							...previousState,
							resultsData: res.data.sort((a, b) => a.event_time > b.event_time ? -1 : 1),
							resultsTotalCount: res.data.length,
							isLoading: false,
						};
					});
				});
		};

		getSummaryData();
	}, [seller, state.filter, state.item, state.operation, state.startDate, state.endDate, context.dataProvider]);

	const handleFilterChange = (value) => {
		setState(previousState => {
			return {
				...previousState,
				filter: value,
			};
		});
	};

	const ItemSelect = () => {
		return (
			<FormControl sx={{ minWidth: 150 }} size="small">
				<InputLabel id="item_select">Item</InputLabel>
				<Select
					labelId="item_select"
					value={state.item}
					label="Item"
					onChange={(e) => {
						setState((previousState) => {
							return {
								...previousState,
								item: e.target.value,
							};
						});
					}}
				>
					<MenuItem key={0} value={""}>None</MenuItem>
					{state.itemData.map(({ id, item }) => (
						<MenuItem key={id} value={item}>
							{capitalizeFirstLetterOfEachWord(item)}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		);
	};

	const OperationSelect = () => {
		return (
			<FormControl sx={{ minWidth: 150 }} size="small">
				<InputLabel id="operation_select">Operation</InputLabel>
				<Select
					labelId="operation_select"
					value={state.operation}
					label="Operation"
					onChange={(e) => {
						setState((previousState) => {
							return {
								...previousState,
								operation: e.target.value,
							};
						});
					}}
				>
					<MenuItem key={0} value={""}>None</MenuItem>
					{state.operationData.map(({ id, operation }) => (
						<MenuItem key={id} value={operation}>
							{capitalizeFirstLetterOfEachWord(operation)}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		);
	};

	const onStartDateChange = (newValue) => {
		setState(previousState => {
			return {
				...previousState,
				startDate: newValue,
			};
		});
	};

	const onEndDateChange = (newValue) => {
		setState((previousState) => {
			return {
				...previousState,
				endDate: newValue,
			};
		});
	};

	const PageHeading = () => {
		return (
			<Grid item xs={12}>
				<Typography variant="h5" sx={{ mb: 1 }}>
					Audit Logs <RecordsTotal total={state.resultsTotalCount} />
				</Typography>
				<Grid item container className="form-inputs">
					<Grid item>
						<SearchFilter
							filter={state.filter} 
							onFilterChange={handleFilterChange}
							label={"User"}
						/>
					</Grid>
					<Grid item xs />
					<Grid item>
						<ItemSelect/>
					</Grid>
					<Grid item xs />
					<Grid item mb={2}>
						<OperationSelect/>
					</Grid>
					<Grid item xs />
					<Grid item mb={2}>
						<StartDatePicker onChange={onStartDateChange} date={state.startDate} />
						<EndDatePicker onChange={onEndDateChange} date={state.endDate} />
					</Grid>
				</Grid>				
			</Grid>
		);
	};

	return (
		<Box id="audit-logs">
			<PermissionDependentView userdata={userdata} permission="view_audit_logs">
				<SellerDependentView seller={seller}>
					<Grid container>
						<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
							<Grid item xs={12}>
								<PageHeading />
								<AuditLogsTable
									tableData={state.resultsData}
									isLoading={state.isLoading}
								/>							
							</Grid>
						</Paper>
					</Grid>
				</SellerDependentView>
			</PermissionDependentView>
		</Box>
	);
}