import React from "react";
import { LastWarehouseUpdateDetails } from "../LastWarehouseUpdate/LastWarehouseUpdateDetails";

export function OrderWarehouseUpdateDetails(props) {
	const orderDetails = props.details;
	const lastDespatchSyncerUpdate = orderDetails.last_despatch_syncer_update;
	const despatchSyncerError = orderDetails.despatch_syncer_error;
	const lastOrderHandlerUpdate = orderDetails.last_order_handler_update;
	const orderHandlerError = orderDetails.order_handler_error;
	const lastbackwardOrderHandlerUpdate = orderDetails.last_backward_order_handler_update;
	const backwardOrderHandlerError = orderDetails.backward_order_handler_error;

	const syncDetails = [
		{ name: "Despatch Syncer", lastUpdate: getTime(lastDespatchSyncerUpdate), error: despatchSyncerError },
		{ name: "Order Handler", lastUpdate: getTime(lastOrderHandlerUpdate), error: orderHandlerError },
		{ name: "Backward Order Handler", lastUpdate: getTime(lastbackwardOrderHandlerUpdate), error: backwardOrderHandlerError },
	];

	const { lastUpdate: lastSynced, error: syncErrors } = joinDetails(syncDetails);

	const details = {
		last_synced: lastSynced,
		sync_errors: syncErrors,
	};

	return <LastWarehouseUpdateDetails details={details} />;
}

function getTime(date) {
	return date ? new Date(date).getTime() : 0;
}

function joinDetails(syncDetails) {
	const latestTime = syncDetails.reduce((a, b) => (Math.max(a, b.lastUpdate)), 0);
	const nameErrors = syncDetails.filter((sync) => sync.error).map((sync) => `${sync.name}: ${sync.error}`);
	const errors = nameErrors.join(" | ");
	return { lastUpdate: latestTime, error: errors };
}