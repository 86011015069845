import React, { useState } from "react";
import {
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Paper,
} from "@mui/material";
import GoodsInStatusButton from "../GoodsInStatusButton";
import {
	getDisplayTime,
	getDisplayDate
} from "../../../utils/date.js";
import { LoadingStatusTable } from "../../Shared/LoadingStatus/LoadingStatus";
import ColumnVisibility from "../../Shared/ColumnVisibility/ColumnVisibility";
import { getASNDeliverySlotStatus } from "../../../utils/deliverySlotStatus";
import { StatusBox } from "../../Shared/StatusBox/StatusBox";
import { LastWarehouseUpdateDetails } from "../../Shared/LastWarehouseUpdate/LastWarehouseUpdateDetails";
import { GoodsInDeliveryQuantity } from "../../Shared/GoodsInDeliveryType/GoodsInDeliveryQuantity.jsx";

export default function GoodsInTable({ tableData, userdata, isLoading }) {

	const [isModalVisible, setIsModalVisible] = useState(false);
	const localStorageName = "goodsin_column_visibility";

	const getInitialColumnVisibility = () => {
		const savedState = localStorage.getItem(localStorageName);
		if (savedState) {
			return JSON.parse(savedState);
		}
		return {
			asnID: true,
			warehouse: true,
			poReference: true,
			createdDate: true,
			estimatedDeliveryDate: true,
			deliveryType: true,
			deliverySlot: true,
			unitsReceived: true,
			status: true,
			lastWarehouseImport: true,
		};
	}; 

	const [columnsVisibility, setColumnsVisibility] = useState(getInitialColumnVisibility());
	const handleVisibilityChange = (newColumnsVisibility) => {
		setColumnsVisibility(newColumnsVisibility);
		setIsModalVisible(true);
		localStorage.setItem(localStorageName, JSON.stringify(newColumnsVisibility));
	};

	const SummaryTableRows = () => {
		if (isLoading) {
			return (
				<LoadingStatusTable message="Loading ..." colSpan={11}/>
			);
		}

		if (typeof tableData === "object" && tableData.length > 0) {
			return (
				tableData.map((item, index) => {
					return (
						<TableRow key={index}>
							<TableCell>{item.id}</TableCell>
							{(userdata.permissions && userdata.permissions.has("view_extra_asn_details")) &&
								<TableCell>{item.inbound_order_number}</TableCell>
							}
							<TableCell style={{ display: columnsVisibility.warehouse ? "" : "none" }}>
								{item.warehouse_code}
							</TableCell>
							<TableCell style={{ display: columnsVisibility.poReference ? "" : "none" }}>
								{item.seller_po_reference}
							</TableCell>
							<TableCell style={{ display: columnsVisibility.createdDate ? "" : "none" }}>
								{getDisplayTime(item.created_date)}
							</TableCell>
							<TableCell style={{ display: columnsVisibility.estimatedDeliveryDate ? "" : "none" }}>
								{getDisplayDate(item.estimated_delivery_date)}
							</TableCell>
							<TableCell style={{ display: columnsVisibility.deliveryType ? "" : "none" }}>
								<GoodsInDeliveryQuantity asn={item} />
							</TableCell>
							<TableCell style={{ display: columnsVisibility.deliverySlot ? "" : "none" }}>
								<StatusBox status={getASNDeliverySlotStatus(item.status.toLowerCase() === "cancelled" ? "" : item)} statusType="delivery" />
							</TableCell>
							<TableCell style={{ display: columnsVisibility.unitsReceived ? "" : "none" }}>
								{item.units_received}/{item.units_expected}
							</TableCell>
							<TableCell style={{ display: columnsVisibility.status ? "" : "none" }}>
								<StatusBox status={item.status} statusType="asn" />
							</TableCell>
							<TableCell style={{ display: columnsVisibility.lastWarehouseImport ? "" : "none"}}>
								<LastWarehouseUpdateDetails details={item} />
							</TableCell>
							<TableCell align="right">
								<GoodsInStatusButton
									asn={item.id}
									asnStatus={item.status}
									hasPaperwork={item.has_paperwork_available}
								/>
							</TableCell>
						</TableRow>
					);
				})
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan={11} align="center">
						<Typography>No data found</Typography>
					</TableCell>
				</TableRow>
			);
		}
	};

	return (
		<>
			<TableContainer sx={{ maxHeight: 600, overflow: "auto" }} component={Paper}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>
								ASN ID
							</TableCell>
							{(userdata.permissions && userdata.permissions.has("view_extra_asn_details")) &&
								<TableCell>
									Warehouse Reference
								</TableCell>
							}
							<TableCell style={{ display: columnsVisibility.warehouse ? "" : "none" }}>
								Warehouse
							</TableCell>
							<TableCell style={{ display: columnsVisibility.poReference ? "" : "none" }}>
								PO Reference
							</TableCell>
							<TableCell style={{ display: columnsVisibility.createdDate ? "" : "none" }}>
								Created Date
							</TableCell>
							<TableCell style={{ display: columnsVisibility.estimatedDeliveryDate ? "" : "none" }}>
								Estimated Delivery Date
							</TableCell>
							<TableCell style={{ display: columnsVisibility.deliveryType ? "" : "none" }}>
								Delivery Type
							</TableCell>
							<TableCell style={{ display: columnsVisibility.deliverySlot ? "" : "none" }}>
								Delivery Slot
							</TableCell>
							<TableCell style={{ display: columnsVisibility.unitsReceived ? "" : "none" }}>
								Units Received
							</TableCell>
							<TableCell style={{ display: columnsVisibility.status ? "" : "none" }}>
								Status
							</TableCell>
							<TableCell style={{ display: columnsVisibility.lastWarehouseImport ? "" : "none" }}>
								Last Warehouse Update
							</TableCell>
							<TableCell align="right">
								<ColumnVisibility
									columnsDisabled={["asnID"]}
									columnsVisibility={columnsVisibility}
									onVisibilityChange={handleVisibilityChange}
									isModalVisible={isModalVisible}
									setIsModalVisible={setIsModalVisible}
								/>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<SummaryTableRows/>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);

}
