import React from "react";
import * as Yup from "yup";
import {
	Form,
	Formik
} from "formik";
import TextFieldComp from "../../GoodsInCreate/components/TextField";
import {
	Box,
	Button
} from "@mui/material";

export function ProductDimensions({
	onProductDimensionsFormSubmit,
	onPanelsVisibleChange,
	existingProduct
}) {
	const INITIAL_FORM_STATE = {
		weight: existingProduct.weight_kg || 0.0,
		length: existingProduct.length_cm || 0.0,
		height: existingProduct.height_cm || 0.0,
		depth: existingProduct.depth_cm || 0.0,
	};

	const FORM_VALIDATION = Yup.object().shape({
		weight: Yup.number()
			.required("Enter the product weight in KG")
			.moreThan(0.0, "Weight must be greater than zero."),
		length: Yup.number()
			.required("Enter the product length in CM")
			.moreThan(0.0, "Length must be greater than zero.")
			.test("length-longest", "Length must be the largest dimension.", function (value) {
				const depth = this.parent.depth;
				const height = this.parent.height;
				return value >= depth && value >= height;
			}),
		height: Yup.number()
			.required("Enter the product height in CM")
			.moreThan(0.0, "Height must be greater than zero."),
		depth: Yup.number()
			.required("Enter the product depth in CM")
			.moreThan(0.0, "Depth must be greater than zero.")
	});

	const onSubmitForm = (values) => {
		onProductDimensionsFormSubmit(values);
		onPanelsVisibleChange({
			isProductDetailsPanelVisible: false,
			isProductDimensionsPanelVisible: false,
			isExtraDetailsPanelVisible: true,
		});
	};

	const handleBackButton = () => {
		onPanelsVisibleChange({
			isProductDetailsPanelVisible: true,
			isProductDimensionsPanelVisible: false,
			isExtraDetailsPanelVisible: false,
		});
	};

	return (
		<>
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE
				}}
				enableReinitialize // disables validation on page load
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				onSubmit={(values) => onSubmitForm(values)}
			>
				{() => (
					<Form
						noValidate
						autoComplete="off"
					>
						<TextFieldComp
							name="weight"
							label="Weight (kg)"
							type="number"
							required
							InputProps={{
								inputProps: {
									min: 0,
									step: 0.01,
								}
							}}
						/>

						<TextFieldComp
							name="length"
							label="Length (cm)"
							type="number"
							required
							InputProps={{
								inputProps: {
									min: 0,
									step: 0.1,
								}
							}}
						/>

						<TextFieldComp
							name="height"
							label="Height (cm)"
							type="number"
							required
							InputProps={{
								inputProps: {
									min: 0,
									step: 0.1,
								}
							}}
						/>

						<TextFieldComp
							name="depth"
							label="Depth (cm)"
							type="number"
							required
							InputProps={{
								inputProps: {
									min: 0,
									step: 0.1,
								}
							}}
						/>

						<Box className="align-content-right">
							<Button
								variant="outlined"
								color="secondary"
								className="back-button"
								onClick={() => handleBackButton()}
							>
								Back
							</Button>
							<Button
								type="submit"
								variant="contained"
							>
								Confirm
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
		</>
	);
}