import React from "react";

export function GoodsInDeliveryQuantity(props) {
	const asn = props.asn;
	const deliveryType = asn.delivery_type;
	const deliveryQuantity = asn.delivery_quantity;

	if (!deliveryType) {
		return null;
	}

	const deliveryTypeNameMap = {
		"carton": "Carton",
		"pallet": "Pallet",
		"fortyftcontainer": "40ft Container",
		"twentyftcontainer": "20ft Container",
		"fortyftcontainerhc": "40ft HC Container",
	};

	const deliveryTypeName = (deliveryTypeNameMap[deliveryType.toLowerCase()] || deliveryType);
	if (!deliveryQuantity || deliveryQuantity === 0) {
		return deliveryTypeName;
	}

	const pluralSuffix = deliveryQuantity > 1 ? "s" : "";

	const deliveryTypeNameWithSuffix = deliveryTypeName + pluralSuffix;

	// TODO not sure exactly what element to return here, just return a <> for now.
	return <>{deliveryQuantity} {deliveryTypeNameWithSuffix}</>;
}