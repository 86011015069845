import { convertToBase64String } from "../utils/file";
import { Buffer } from "buffer";

const jsonContentTypeOptions = { headers: { "Content-Type": "application/json" } };
const formDataOptions = { headers: { Accept: "multipart/form-data" } };

const octetContentTypeOptions = {
	headers: { Accept: "application/octet-stream" },
	responseType: "blob"
};

const toBase64 = (content) => Buffer.from(content).toString("base64");

export default class DataProvider {
	constructor(requestProvider) {
		this.requestProvider = requestProvider;
		this.lastInstantiated = new Date();
	}

	getIfIDIsValid = async (id, url, options) => {
		if (!id) return;
		return await this.requestProvider.get(url, options);
	};

	postIfIDIsValid = async (id, url, value, options) => {
		if (!id) return;
		return this.requestProvider.post(url, value, options);
	};

	putIfIDIsValid = async (id, url, value, options) => {
		if (!id) return;
		return this.requestProvider.put(url, value, options);
	};

	deleteIfIDIsValid = async (id, url, options) => {
		if (!id) return;
		return this.requestProvider.delete(url, options);
	};

	// ASNs
	getASN = async (asnID) =>
		this.getIfIDIsValid(asnID, "/v1/asn/" + encodeURIComponent(asnID));

	getSellerASNTotal = async (sellerID) => 
		this.getIfIDIsValid(sellerID, "/v1/asns/total?" + new URLSearchParams({ seller: sellerID }));
		
	getSellerASNs = async (sellerID, page, results) =>
		this.getIfIDIsValid(sellerID, "/v1/asns?" + new URLSearchParams({ seller: sellerID, page, results }));

	getASNPaperwork = async (asnID) =>
		this.getIfIDIsValid(asnID, "/v1/asn/" + encodeURIComponent(asnID) + "/paperwork");

	getSellerASNTemplate = async (sellerID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, "/v1/asn-template?" + params, octetContentTypeOptions);
	};

	updateASNTrackingInformation = async (asnID, trackingInformation) =>
		this.putIfIDIsValid(asnID, "/v1/asn/" + encodeURIComponent(asnID) + "/tracking-information",
			trackingInformation, jsonContentTypeOptions);

	createSellerASN = async (sellerID, asn) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.postIfIDIsValid(sellerID, "/v1/asn?" + params, JSON.stringify(asn), jsonContentTypeOptions);
	};
	
	updateSellerASN = async (sellerID, asn) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.putIfIDIsValid(sellerID, "/v1/asn?" + params, JSON.stringify(asn), jsonContentTypeOptions);
	};

	cancelASN = async (asnID) =>
		this.getIfIDIsValid(asnID, "/v1/asn/" + encodeURIComponent(asnID) + "/cancel", jsonContentTypeOptions);

	deleteASN = async (asnID) =>
		this.deleteIfIDIsValid(asnID, "/v1/asn/" + encodeURIComponent(asnID), jsonContentTypeOptions);

	getDeliverySlotRequirements = async (warehouseID) =>
		this.getIfIDIsValid(warehouseID, "/v1/delivery-slot-requirements/" + encodeURIComponent(warehouseID));

	createASNDeliverySlot = async (asnID, deliverySlot) =>
		this.postIfIDIsValid(asnID, "/v1/asn/" + encodeURIComponent(asnID) + "/delivery", JSON.stringify(deliverySlot), jsonContentTypeOptions);

	approveASNDeliverySlot = async (sellerID, asnID, date) =>
		this.postIfIDIsValid(sellerID, "/v1/asn/" + encodeURIComponent(asnID) + "/approval", { seller: sellerID, delivery_slot_date: date }, jsonContentTypeOptions);

	rejectASNDeliverySlot = async (sellerID, asnID, date) =>
		this.postIfIDIsValid(sellerID, "/v1/asn/" + encodeURIComponent(asnID) + "/rejection", { seller: sellerID, proposed_date: date }, jsonContentTypeOptions);

	getSellerInvoices = async (sellerID) =>
		this.getIfIDIsValid(sellerID, "/v1/invoices/" + encodeURIComponent(sellerID));

	getSellerInvoice = async (sellerID, invoiceID) =>
		this.getIfIDIsValid(sellerID, "/v1/invoices/" + encodeURIComponent(sellerID) + "/" + encodeURIComponent(invoiceID), octetContentTypeOptions);

	deleteSellerInvoice = async (sellerID, invoiceID) =>
		this.deleteIfIDIsValid(sellerID, "/v1/invoices/" + encodeURIComponent(sellerID) + "/" + encodeURIComponent(invoiceID), jsonContentTypeOptions);

	createSellerInvoice = async (sellerID, billingPeriod, formData) =>
		this.postIfIDIsValid(sellerID, "/v1/invoices/" + encodeURIComponent(sellerID) + "/" + encodeURIComponent(billingPeriod),
			formData, formDataOptions);

	// Product / stock
	getStock = async (sellerID, warehouseID, showHiddenSKUs, statusID) => {
		const params = new URLSearchParams({ seller: sellerID, warehouse: warehouseID, show_hidden_skus: showHiddenSKUs, status_id: statusID });
		return this.getIfIDIsValid(sellerID, "/v1/stock?" + params);
	};

	getAllProducts = async (sellerID, statusID) => {
		const params = new URLSearchParams({ seller: sellerID, status_id: statusID });
		return this.getIfIDIsValid(sellerID, "/v1/products?" + params);
	};

	getAllBundles = async (sellerID) => {
		const params = new URLSearchParams({ seller: sellerID }); 
		return this.getIfIDIsValid(sellerID, "/v1/bundles?" + params);
	};

	createProduct = async (sellerID, product) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.postIfIDIsValid(sellerID, "/v1/product?" + params, JSON.stringify(product), jsonContentTypeOptions);
	};

	getProductStatuses = async () => this.requestProvider.get("/v1/product-statuses");

	updateProductStatuses = async (sellerID, data) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.putIfIDIsValid(sellerID, "/v1/product-status?" + params, JSON.stringify(data), jsonContentTypeOptions);
	};

	updateProduct = async (sellerID, product) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.putIfIDIsValid(sellerID, "/v1/product?" + params, JSON.stringify(product), jsonContentTypeOptions);
	};

	getProduct = async (product) =>
		this.getIfIDIsValid(product, "/v1/product/" + encodeURIComponent(product));

	getProductStock = async (sellerID, product) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, "/v1/product/" + encodeURIComponent(product) + "/stock?" + params);
	};

	hideProducts = async (sellerID, skus) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.putIfIDIsValid(sellerID, "/v1/hidden-products?" + params, JSON.stringify({ skus }), jsonContentTypeOptions);
	};

	// Orders
	getSummaryActions = async (sellerID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, "/v1/summary/actions?" + params);
	};

	getSummaryOrderTotals = async (sellerID, startDate, endDate) => {
		const params = new URLSearchParams({ seller: sellerID, start_date: startDate, end_date: endDate });
		return this.getIfIDIsValid(sellerID, "/v1/summary/order-totals?" + params);
	};

	getSummaryDeliveryPerformance = async (sellerID, startDate, endDate) => {
		const params = new URLSearchParams({ seller: sellerID, start_date: startDate, end_date: endDate });
		return this.getIfIDIsValid(sellerID, "/v1/summary/delivery-performance?" + params);
	};

	getOrderDetail = async (sellerID, order) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, "/v1/order/" + encodeURIComponent(order) + "/detail?" + params);
	};

	getReturnLabels = async (sellerID, orderNumber) => {
		const params = new URLSearchParams({ seller: sellerID, order_number: orderNumber });
		return this.getIfIDIsValid(sellerID, "/v1/return-labels?" + params);
	};

	getCommercialInvoice = async (orderID) =>
		this.getIfIDIsValid(orderID, "/v1/order/" + encodeURIComponent(orderID) + "/commercial-invoice");

	createOrder = async (sellerID, order) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.postIfIDIsValid(sellerID, "/v1/order/?" + params, JSON.stringify(order), jsonContentTypeOptions);
	};

	createOrdersFromFile = async (sellerID, warehouseID, file) => {
		const requestBody = new FormData();
		requestBody.append("File", file);

		const params = new URLSearchParams({ seller: sellerID, warehouse: warehouseID });

		return this.postIfIDIsValid(sellerID, "/v1/orders?" + params, requestBody, formDataOptions);
	};

	createOrderDocuments = async (sellerID, orderID, documents) => {

		const params = new URLSearchParams({ seller: sellerID });
		const documentsData = { documents: [] };

		for (const f of documents) {
			await convertToBase64String(f.content[0])
				.then(content => {
					documentsData.documents.push({ type_id: f.type_id, filename: f.filename, document_count: f.document_count, document_size: f.document_size, paper_size_id: f.paper_size_id, content });
				});
		}

		return this.postIfIDIsValid(sellerID, "/v1/order/" + encodeURIComponent(orderID) + "/documents?" + params,
			documentsData, formDataOptions);
	};

	getDocumentSizes = async () => this.requestProvider.get("/v1/order/document/paper-sizes");

	getOrderDocuments = async (sellerID, orderID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, "/v1/order/" + encodeURIComponent(orderID) + "/documents?" + params);
	};

	downloadOrderDocument = async (sellerID, orderID, documentID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, "/v1/order/" + encodeURIComponent(orderID) + "/document/" + encodeURIComponent(documentID) + "?" + params, octetContentTypeOptions);
	};

	updateOrder = async (sellerID, order) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.putIfIDIsValid(sellerID, "/v1/order/?" + params, order, jsonContentTypeOptions);
	};

	updateOrderDocuments = async (sellerID, orderID, documents) => {

		const params = new URLSearchParams({ seller: sellerID });
		const documentsData = { documents: [] };

		for (const f of documents) {
			if (f.content[0] !== undefined) {
				await convertToBase64String(f.content[0]) // dont call for existing files
					.then(content => {
						documentsData.documents.push({ type_id: f.type_id, filename: f.filename, document_count: f.document_count, document_size: f.document_size, paper_size_id: f.paper_size_id, content });
					});
			}

			if (f.content[0] === undefined) {
				documentsData.documents.push({ type_id: f.type_id, filename: f.filename, document_count: f.document_count, document_size: f.document_size, paper_size_id: f.paper_size_id, content: "" });
			}
		}

		return this.putIfIDIsValid(sellerID, "/v1/order/" + encodeURIComponent(orderID) + "/documents?" + params,
			documentsData, formDataOptions);
	};

	// Orders (cancel and split)
	getOrderCancellationRequest = async (sellerID, orderID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, "/v1/order/" + encodeURIComponent(orderID) +
			"/cancellation-request?" + params);
	};

	createOrderCancellationRequest = async (sellerID, orderID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.postIfIDIsValid(sellerID, "/v1/order/" + encodeURIComponent(orderID) +
		"/cancellation-request?" + params, {}, jsonContentTypeOptions);
	};

	splitOrder = async (sellerID, orderID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, "/v1/order/" + encodeURIComponent(orderID) +
			"/split?" + params, jsonContentTypeOptions);
	};

	cancelOrder = async (sellerID, orderID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, "/v1/order/" + encodeURIComponent(orderID) +
			"/cancel?" + params, jsonContentTypeOptions);
	};

	releaseOrder = async (sellerID, orderID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, "/v1/order/" + encodeURIComponent(orderID) +
			"/release-order?" + params, jsonContentTypeOptions);
	};

	forwardSyncOrder = async (sellerID, orderNumber) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.postIfIDIsValid(sellerID, "/v1/order/" + encodeURIComponent(orderNumber) + "/sync/forward?" + params, {}, jsonContentTypeOptions);
	}

	backwardSyncOrder = async (sellerID, orderNumber) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.postIfIDIsValid(sellerID, "/v1/order/" + encodeURIComponent(orderNumber) + "/sync/backward?" + params, {}, jsonContentTypeOptions);
	}

	// Orders (WIMO)
	getWIMODetail = async (orderNumber) =>
		this.getIfIDIsValid(orderNumber, "/v1/order/" + encodeURIComponent(orderNumber) + "/wimo", jsonContentTypeOptions);

	getWIMOSummaryTotal = async (pathParams, queryParams) =>
		this.requestProvider.get("/v1/wimo/total/" + encodeURIComponent(pathParams.startDate) + "/" + encodeURIComponent(pathParams.endDate) +
			"?" + new URLSearchParams(queryParams));

	getWIMOSummary = async (pathParams, queryParams) =>
		this.requestProvider.get("/v1/wimo/" + encodeURIComponent(pathParams.startDate) + "/" + encodeURIComponent(pathParams.endDate) +
			"?" + new URLSearchParams(queryParams));

	getBulkCancelUnprocessedOrders = async (sellerID, orderIDs) => {
		const params = new URLSearchParams({ seller: sellerID });
		
		for (let order of orderIDs) {
			params.append("order_ids", order);
		}
	
		try {
			return this.getIfIDIsValid(sellerID, "/v1/orders/cancel?" + params);
		} catch (error) {
			return error.response;
		}
	};

	// Proof of Delivey
	createProofOfDeliveryRequest = async (orderNumber, sellerID) => {
		const params = new URLSearchParams({ entity_id: orderNumber, seller: sellerID, request_type: "PROOF_OF_DELIVERY" });
		return this.postIfIDIsValid(sellerID, "/v1/support-request?" + params);
	};

	getProofOfDelivery = async (orderNumber, sellerID, trackingNumber, courierService) => {
		const params = new URLSearchParams({ seller: sellerID, tracking_number: trackingNumber, courier_service: courierService });

		return this.getIfIDIsValid(sellerID, "/v1/proof-of-delivery/" + encodeURIComponent(orderNumber) + "?" + params);
	};

	// Reference data
	getOrderTimeline = async (sellerID, orderID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, `/v1/order/${orderID}/timeline?` + params);
	};

	getSellerWarehouses = async (sellerID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, "/v1/warehouses?" + params);
	};

	getSellerCountries = async (sellerID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, "/v1/countries/?" + params);
	};

	getSellerCourierServicesForWarehouse = async (sellerID, warehouseID, countryID) => {
		const params = new URLSearchParams();
		params.append("seller", sellerID);
		params.append("warehouse", warehouseID);
		if (countryID !== undefined) {
			params.append("country_id", countryID);
		}
		return this.getIfIDIsValid(sellerID, "/v1/courier-services?" + params.toString(), jsonContentTypeOptions);
	};
	

	getAllCourierServicesForWarehouse = async (warehouseID) => {
		const params = new URLSearchParams({ warehouse: warehouseID });
		return this.getIfIDIsValid(warehouseID, "/v1/courier-services?" + params, jsonContentTypeOptions);
	};

	getAllReturnCourierServicesForOrder = async(sellerID, orderID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, "/v1/order/" + encodeURIComponent(orderID) + "/return-courier-services?" + params);
	};

	updateSellerCourierServicesForWarehouse = async (sellerID, warehouseID, courierServices) => {
		const params = new URLSearchParams({ warehouse: warehouseID });
		return this.putIfIDIsValid(sellerID, "/v1/courier-services/" + encodeURIComponent(sellerID) + "?" + params, courierServices, jsonContentTypeOptions);
	};

	// Returns
	getSellerReturn = async (sellerID, returnID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, "/v1/return/" + encodeURIComponent(returnID) + "?" + params);
	};
	
	getSellerReturns = async (sellerID, startDate, endDate, page, results, filter) => {
		const url = "/v1/returns/" + startDate.toISOString() + "/" + endDate.toISOString();
		const params = new URLSearchParams({ page, results, filter, sellers: sellerID });
		return this.getIfIDIsValid(sellerID, url + "?" + params);
	};

	getSellerReturnsTotal = async (sellerID, startDate, endDate, filter) => {
		const url = "/v1/returns/total/" + startDate.toISOString() + "/" + endDate.toISOString();
		const params = new URLSearchParams({ filter, sellers: sellerID });
		return this.getIfIDIsValid(sellerID, url + "?" + params);
	};

	createSellerReturnLabel = async (sellerID, orderNumber, postcode, carrierCode, deliveryOption, productItems) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.postIfIDIsValid(sellerID, "/v1/return-label?" + params, { order_number: orderNumber, postcode: postcode, carrier_code: carrierCode, delivery_option: deliveryOption, items: productItems }, jsonContentTypeOptions);
	};

	pollSellerReturnLabel = async (sellerID, orderNumber, pollerToken) => {
		const params = new URLSearchParams({ seller: sellerID, order_number: orderNumber, poller_token: pollerToken });
		return this.getIfIDIsValid(sellerID, "/v1/return-label/poll?" + params.toString(), jsonContentTypeOptions);
	}

	downloadSellerReturnLabel = async (sellerID, orderNumber, downloadToken) => {
		const params = new URLSearchParams({ seller: sellerID, order_number: orderNumber, download_token: downloadToken });
		return this.getIfIDIsValid(sellerID, "/v1/return-label/download?" + params.toString(), octetContentTypeOptions);
	};

	getLateDeliveries = async (sellerID, startDate, endDate) => {
		const params = new URLSearchParams({ 
			seller: sellerID,
			start_date: startDate,
			end_date: endDate 
		});
		return this.getIfIDIsValid(sellerID, "/v1/late-deliveries?" + params, jsonContentTypeOptions);
	};

	getOrderClaimTypes = async () => this.requestProvider.get("/v1/order-claim-types");
	
	// Claims
	getClaimsSummary = async (sellerID, params) => {
		const url = "/v1/order-claims?";
		return this.getIfIDIsValid(sellerID, url + params);
	};

	createOrderClaim = async (sellerID, orderClaim) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.postIfIDIsValid(sellerID, "/v1/order-claim/?" + params, JSON.stringify(orderClaim), jsonContentTypeOptions);
	}

	// Audit Logs
	getAuditLogs = async (sellerID, params) => {
		const url = "/v1/audit/logs?";
		return this.getIfIDIsValid(sellerID, url + params, jsonContentTypeOptions);
	};

	getAuditItems = async () => this.requestProvider.get("/v1/audit/items");

	getAuditOperations = async () => this.requestProvider.get("/v1/audit/operations");

	// Templates
	getTemplates = async () => this.requestProvider.get("/v1/order/templates");

	getStockTemplate = async () => this.requestProvider.get("/v1/stock/template");

	getStockUploadTemplate = async () => this.requestProvider.get("/v1/stock/upload-template");

	getTemplate = async (filename) =>
		this.getIfIDIsValid(filename, "/v1/template/" + toBase64(filename), jsonContentTypeOptions);

	// Reports
	getReportsTotal = async (sellerID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, "/v1/reports/total?" + params, jsonContentTypeOptions);
	};

	getReports = async (sellerID, page, results) => {
		const params = new URLSearchParams({ seller: sellerID, page, results });
		return this.getIfIDIsValid(sellerID, "/v1/reports/?" + params, jsonContentTypeOptions);
	};

	getScheduledReports = async (sellerID, page, results) => {
		const params = new URLSearchParams({ seller: sellerID, page, results });
		return this.getIfIDIsValid(sellerID, "/v1/reports/schedules/?" + params, jsonContentTypeOptions);
	};

	deleteScheduledReport = async (sellerID, reportID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.deleteIfIDIsValid(sellerID, "/v1/reports/schedule/" + encodeURIComponent(reportID) + "?" + params, jsonContentTypeOptions);
	};

	getReportFile = async (sellerID, filename) =>
		this.getIfIDIsValid(sellerID, "/v1/report/" + encodeURIComponent(sellerID) + "/" + toBase64(filename), octetContentTypeOptions);

	getReportFileURL = async (sellerID, filename) =>
		this.getIfIDIsValid(sellerID, "/v1/report-url/" + encodeURIComponent(sellerID) + "/" + toBase64(filename), jsonContentTypeOptions);

	createReport = async (sellerID, reportTypeID, startDate, endDate) => {
		const body = {
			start_date: startDate.toISOString(),
			end_date: endDate.toISOString(),
			report_type_id: reportTypeID,
			seller_id: sellerID
		};
		return this.postIfIDIsValid(sellerID, "/v1/report", body, jsonContentTypeOptions);
	};

	createScheduledReport = async (sellerID, reportTypeID, frequency, day, email) => {
		const body = {
			seller_id: sellerID,
			report_type_id: reportTypeID,
			frequency_id: frequency,
			requested_run_day: day || null,
			recipient: email
		};
		return this.postIfIDIsValid(sellerID, "/v1/reports/schedule", body, jsonContentTypeOptions);
	};

	// Settings
	getSellerSettings = async (sellerID) =>
		this.getIfIDIsValid(sellerID, "/v1/client-settings/" + encodeURIComponent(sellerID), jsonContentTypeOptions);

	updateSellerSettings = async (sellerID, settings) =>
		this.putIfIDIsValid(sellerID, "/v1/client-settings/" + encodeURIComponent(sellerID), settings, jsonContentTypeOptions);

	createUser = async (sellerID, username, email) => {
		const params = new URLSearchParams({ seller: sellerID, username: username, email: email });
		return this.postIfIDIsValid(sellerID, "/v1/user?" + params, {}, jsonContentTypeOptions);
	};

	// Features
	getSellerFeatures = async (sellerID) =>
		this.getIfIDIsValid(sellerID, "/v1/features/" + encodeURIComponent(sellerID), jsonContentTypeOptions);

	// Upload Adaptor
	getFileTypes = async () => this.requestProvider.get("/v1/seller-file/types");

	updateFile = async (sellerID, fileName, fileTypeID, content) => {
		const params = new URLSearchParams({ seller: sellerID });
		const body = {
			filename: fileName,
			file_type_id: fileTypeID,
			content: content
		};
		return this.putIfIDIsValid(sellerID, "/v1/seller-file?" + params, body, jsonContentTypeOptions);
	};

	// Others
	getSellers = async () => this.requestProvider.get("/v1/sellers");

	getOrderStatuses = async () => this.requestProvider.get("/v1/order/statuses");
	getCourierStatuses = async () => this.requestProvider.get("/v1/courier-statuses");

	getReportTypes = async () => this.requestProvider.get("/v1/report-types/");

	getMenu = async () => this.requestProvider.get("/v1/menu");
	getUserData = async () => this.requestProvider.get("/v1/userdata");

	getAllUsers = async (sellerID) => {
		const params = new URLSearchParams({ seller: sellerID });
		return this.getIfIDIsValid(sellerID, "/v1/users?" + params, jsonContentTypeOptions);
	};

	getUserEmail = async (sellerID, username) => {
		const params = new URLSearchParams({ seller: sellerID, username: username });
		return this.getIfIDIsValid(sellerID, "/v1/user/email?" + params, jsonContentTypeOptions);
	};

	getAlertBanner = async () => this.requestProvider.get("/v1/banner");
}
