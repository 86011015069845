import React, {
	useState,
	useEffect,
	useCallback
} from "react";
import {
	Button,
	Box
} from "@mui/material";
import TextFieldComp from "../../components/TextField";
import {
	Formik,
	Form
} from "formik";
import * as Yup from "yup";
import useFetchData from "../../../Shared/FetchData/FetchData";
import { getNextDayTruncated } from "../../../../utils/date";
import DatePickerField from "../../components/DatePickerField";
import { DataContext } from "../../../../App";
import DropDown from "../../../Shared/DropDown";
import { isToday } from "date-fns";

export default function ShippingDetails({
	sellerID,
	onWarehouseChange,
	onShippingDetailsFormSubmit,
	shippingFormData,
	countries,
	onPanelsVisibleChange,
	isASNDraft,
}) {

	const context = React.useContext(DataContext);
	const warehouses = useFetchData(useCallback(() => context.dataProvider.getSellerWarehouses(sellerID), [context.dataProvider, sellerID]));
	
	const delivery_types = [
		{
			id: 1,
			name: "Carton",
		},
		{
			id: 2,
			name: "Pallet",
		},
		{
			id: 3,
			name: "20ft Container",
		},
		{
			id: 4,
			name: "40ft Container",
		},
		{
			id: 5,
			name: "40ft HC Container"
		}
	];

	const [warehouseID, setWarehouseID] = useState(0);
	const [countryOfOrigin, setCountryOfOrigin] = useState(0);

	useEffect(() => {
		const getWarehouseID = async () => {

			if (typeof shippingFormData.warehouse === "string") {

				const warehouses = warehouses.results;
				const selectedWarehouse = await warehouses.find(obj => obj.short_code === shippingFormData.warehouse);

				if (selectedWarehouse) {
					const warehouse_ID = selectedWarehouse.id;
					setWarehouseID(warehouse_ID);
				}

			} else {
				setWarehouseID(shippingFormData.warehouse_id);				
			}

		};
		getWarehouseID();
	}, [warehouses.results, shippingFormData.warehouse, onPanelsVisibleChange]);

	useEffect(() => {
		const getCountryOfOrigin = async () => {

			const selectedCountryCode = isASNDraft
				? shippingFormData.country_of_origin_code
				: shippingFormData.country_of_origin;

			if (!selectedCountryCode) return;

			try {
				let countryOfOrigin;

				if (typeof selectedCountryCode === "string") {
					const selectedCountry = countries.results.find(
						(obj) => obj.code === selectedCountryCode
					);

					if (selectedCountry) {
						countryOfOrigin = selectedCountry.platform_id;
					}
				} else {
					countryOfOrigin = selectedCountryCode;
				}

				if (countryOfOrigin) {
					setCountryOfOrigin(countryOfOrigin);
				}
			} catch (error) {
				console.error("Error populating country of origin:", error);
			}

		};
		getCountryOfOrigin();
	}, [countries.results, shippingFormData, onPanelsVisibleChange]);

	const INITIAL_FORM_STATE = {
		warehouse_id: warehouseID || "",
		estimated_delivery_date: shippingFormData.estimated_delivery || getNextDayTruncated(),
		delivery_type: shippingFormData.delivery_type || "",
		delivery_quantity: shippingFormData.delivery_quantity || "",
		country_of_origin: countryOfOrigin || "",
		courier_service: shippingFormData.carrier || "",
		tracking_number: shippingFormData.tracking_number || "",
		seller_po_reference: shippingFormData.seller_po_reference || "",
	};

	const FORM_VALIDATION = Yup.object().shape({
		warehouse_id: Yup.number()
			.required("Please select a Warehouse from the list"),
		estimated_delivery_date: Yup.date()
			.required("Estimated delivery date required"),
		delivery_type: Yup.string()
			.required("Please select a Delivery Type from the list"),
		delivery_quantity: Yup.number()
			.required("Quantity required")
			.moreThan(0, "Quantity must be greater than 0"),
		country_of_origin: Yup.number()
			.required("Please select a Country of Origin from the list"),
		tracking_number: Yup.string()
			.when("warehouse_id", (warehouse_id, schema) => {
				const warehouse = warehouses.results.find(w => w.id === Number(warehouse_id));
				if (warehouse && warehouse.requires_tracking_number_for_asn) {
					return schema.required("Tracking number required");
				}
				return schema;
			}),
	});

	const onSubmitForm = (values) => {
		onShippingDetailsFormSubmit(values);
		onWarehouseChange(values.warehouse_id);
		onPanelsVisibleChange({
			isShippingDetailsPanelVisible: false,
			isAddProductsPanelVisible: true,
			isAdditionalDetailsPanelVisible: false,
		});
	};

	const deliveryQuantityLabel = (deliveryType) => {
		if (!deliveryType) {
			return "Number of Cartons/Containers/Pallets";
		}

		return `Number of ${deliveryType}s`;
	};

	return (
		<>
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE
				}}
				enableReinitialize // disables validation on page load
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				onSubmit={(values) => onSubmitForm(values)}
			>
				{({ values }) => (
					<Form
						noValidate
						autoComplete="off"
					>
						<DropDown
							id="warehouse_id"
							name="warehouse_id"
							label="Warehouse"
							value={values.warehouse_id}
							data={warehouses.results.map((w) => ({
								key: w.id,
								value: w.id,
								name: w.name
							}))}
							required={true}
						/>

						<DatePickerField
							name="estimated_delivery_date"
							label="Estimated Delivery Date"
							disablePast={true}
							disableHighlightToday={true}
							shouldDisableDate={(date) => isToday(date)}
							required
						/>

						<DropDown
							id="delivery_type"
							name="delivery_type"
							label="Delivery Type"
							value={values.delivery_type}
							data={delivery_types.map((d) => ({
								key: d.id,
								value: d.name,
								name: d.name
							}))}
							required={true}
						/>

						<TextFieldComp
							name="delivery_quantity"
							label={deliveryQuantityLabel(values.delivery_type)}
							type="number"
							required
							InputProps={{
								inputProps: {
									min: 1,
								}
							}}
						/>

						<DropDown
							id="country_of_origin"
							name="country_of_origin"
							label="Country of Origin"
							value={values.country_of_origin}
							data={countries.results.sort((b, a) => a.name > b.name ? -1 : 1).map((c) => ({
								key: c.platform_id,
								value: c.platform_id,
								name: c.name
							}))}
							required={true}
						/>

						<TextFieldComp
							name="courier_service"
							label="Carrier"
						/>

						<TextFieldComp
							name="tracking_number"
							label="Tracking Number"
						/>

						<TextFieldComp
							name="seller_po_reference"
							label="PO Reference"
						/>

						<Box className="align-content-right">
							<Button
								type="submit"
								variant="contained"
							>
								Confirm
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
		</>
	);

}